import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './place-hero.module.scss'

const PlaceHero = ({ image, title, blurb, preheader, buttonText, buttonLink }) => (
  <div className={styles.projectHero}>
    <div className={styles.details}>
      {preheader && 
        <h3 className={styles.quip}>{preheader}</h3>
      }
      {title &&
        <h1 className={styles.title}>{title}</h1>
      }
      {blurb && 
        <h3 className={styles.blurb}>{blurb}</h3>
      }
    </div>
    <div className={styles.imageCover} />
    {image && (
      <div className={styles.image}>
        <GatsbyImage alt={title} image={image} />
      </div>
    )}
    {buttonText && buttonLink &&
      <a href="{buttonLink}" className="btn">
        {buttonText}
      </a>
    }
  </div>
)

export default PlaceHero
