import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import PlaceHero from '../components/places/hero/place-hero'

class PlaceTemplate extends React.Component {

  render() {
    const place = get(this.props, 'data.contentfulPlace')

    const address = formatAddress(place);



    return (
      <Layout location={this.props.location}>
        <Seo
          title={place.name}
          description={''}
          image={''}
        />
          <PlaceHero
            // image={project.heroImage.gatsbyImageData}
            title={place.name}
            preheader={"PLACE"}
          />

          {address}
      </Layout>
    )
  }
}

export default PlaceTemplate

export const pageQuery = graphql`
  query PlaceBySlug(
    $slug: String!
  ) {
    contentfulPlace(slug: { eq: $slug }) {
        name
        slug
        address
        city {
          name
        }
        state
        zip

    }
  }
`
function formatAddress(place) {
  return `${place?.address ?? ''}${place?.city?.name ? ', ' + place.city.name : ''}${place?.state ? ', ' + place.state : ''}${place?.zip ? ' ' + place.zip : ''}`;
}
